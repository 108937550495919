<template>
  <div v-if="!isFormSubmitted">
    <b-card>
      <b-form @submit.prevent v-if="showForm">
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="name"
              :label="$t(`${moduleSlug}.name-label`)"
              label-for="name"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.name.$error }"
            >
              <b-form-input
                id="name"
                v-model="formData.name"
                :placeholder="$t(`${moduleSlug}.name-placeholder`)"
                trim
                :class="{ 'is-invalid': $v.formData.name.$error }"
              ></b-form-input>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="name-feedback">
                {{ $t(`${moduleSlug}.name-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
          <div class="col-sm-12 col-md-6">
            <b-form-group
              id="siteId"
              :label="$t(`${moduleSlug}.select_site-label`)"
              label-for="siteId"
              :label-class="['form-label', 'required']"
              :class="{ 'form-group--error': $v.formData.siteId.$error }"
            >
              <b-dropdown
                id="site-selection-dropdown"
                :text="
                  formData.siteId == ''
                    ? $t(`${moduleSlug}.select_site-placeholder`)
                    : selectedSite.Name
                "
                ref="dropdown"
                block
                menu-class="w-100 scrollable"
                no-caret
                lazy
                variant="brand-dropdown"
                :toggle-class="{ 'is-invalid': $v.formData.siteId.$error }"
                :class="{ 'is-invalid': $v.formData.siteId.$error }"
              >
                <b-dropdown-item
                  v-for="(site, index) in sitesList"
                  :key="index"
                  @click="formData.siteId = site.value"
                  :active="formData.siteId == site.value"
                  >{{ site.text }}</b-dropdown-item
                >
              </b-dropdown>

              <!-- This will only be shown if the preceding input has an invalid state -->
              <b-form-invalid-feedback id="siteId-feedback">
                {{ $t(`${moduleSlug}.select_site-required-error-msg`) }}
              </b-form-invalid-feedback>
            </b-form-group>
          </div>
        </div>

        <div role="group" class="form-group">
          <label for="chooseProducts" class="form-label required">{{
            $t(`${moduleSlug}.choose_product-label`)
          }}</label>
          <i class="icon-tooltip ml-1" id="productFilters-tootltip"></i>
          <b-tooltip target="productFilters-tootltip"
            ><p v-html="$t(`${moduleSlug}.choose_product-tooltip`)"
          /></b-tooltip>

          <div class="product-filters" id="productFilters">
            <product-filters
              :filterLabel="$t(`${moduleSlug}.choose_product-label`)"
              v-for="(productGroup, index) in productGroups"
              :key="index"
              :id="productGroup.groupId"
              :index="index"
              :totalGroups="productGroups.length"
              :requiredFilters="requiredFilters"
              :disabled="formData.siteId == ''"
              @removeProductGroup="removeProductGroup"
              :class="{
                'single-filter':
                  !enableProductGroupBoxStyling && !enableAddProductGroup,
                'is-invalid':
                  formData.siteId != '' &&
                  $v.productGroups.isInvalidProductGroup &&
                  $v.productGroups.$dirty,
              }"
              :moduleSlug="moduleSlug"
              :controllerName="controllerName"
              :moduleName="moduleName"
              :isPushPricing="isPushPricing"
            />
            <b-form-invalid-feedback>
              {{
                !enableProductGroupBoxStyling && !enableAddProductGroup
                  ? $t(`${moduleSlug}.choose_product-required-error-msg`)
                  : $t(`${moduleSlug}.choose_product-required-error-msg`) +
                    availableProductGroups
              }}
            </b-form-invalid-feedback>
          </div>
          <b-link
            variant="link"
            class="btn-link"
            @click="createNewProductGroup"
            v-if="enableProductGroupBoxStyling && enableAddProductGroup"
            :disabled="formData.siteId == ''"
            >Add New Product-Group</b-link
          >
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div id="priceEnding" role="group" class="form-group">
              <label class="form-label required">{{
                $t(`${moduleSlug}.price_ending-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="priceEnding-tootltip"></i>
              <b-tooltip target="priceEnding-tootltip"
                ><p v-html="$t(`${moduleSlug}.price_ending-tooltip`)"
              /></b-tooltip>
              <b-form-checkbox
                id="checkbox_priceEndings"
                v-model="enablePriceEndings"
                name="priceEndings"
                class="mb-1"
              >
                {{ $t(`${moduleSlug}.price_ending_agree-msg`) }}
              </b-form-checkbox>
              <b-input-group
                :class="{
                  'is-invalid':
                    $v.formData.pricingEndings1.$error ||
                    $v.formData.pricingEndings2.$error,
                }"
              >
                <template #prepend>
                  <b-form-select
                    class="form-select"
                    v-model="formData.pricingEndings1"
                    :options="priceEndingOptions"
                    :disabled="
                      !enablePriceEndings || enablePriceEndings == 'false'
                    "
                    :class="{
                      'is-invalid':
                        $v.formData.pricingEndings1.$error ||
                        $v.formData.pricingEndings2.$error,
                    }"
                  >
                  </b-form-select>
                </template>
                <b-form-input
                  v-model="formData.pricingEndings2"
                  @keypress="allowOnlyNumber"
                  :disabled="
                    !enablePriceEndings || enablePriceEndings == 'false'
                  "
                  :class="{
                    'is-invalid':
                      $v.formData.pricingEndings1.$error ||
                      $v.formData.pricingEndings2.$error,
                  }"
                >
                </b-form-input>
              </b-input-group>
              <b-form-invalid-feedback id="priceEnding-feedback">
                {{
                  $v.formData.pricingEndings2.maxValue == false
                    ? $t(`${moduleSlug}.price_ending-invalid-error-msg`)
                    : $t(`${moduleSlug}.price_ending-required-error-msg`)
                }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-12 col-md-6" v-if="showDiscount">
            <div id="discount" role="group" class="form-group">
              <label class="form-label required">{{
                $t(`${moduleSlug}.discount-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="discount-tootltip"></i>
              <b-tooltip target="discount-tootltip"
                ><p v-html="$t(`${moduleSlug}.discount-tooltip`)"
              /></b-tooltip>

              <b-input-group
                :class="{
                  'is-invalid':
                    $v.formData.discount.$error ||
                    $v.formData.discountType.$error,
                }"
              >
                <b-form-input
                  v-model="formData.discount"
                  @keypress="onlyForDiscount"
                  :class="{
                    'is-invalid':
                      $v.formData.discount.$error ||
                      $v.formData.discountType.$error,
                  }"
                >
                </b-form-input>
                <template #append>
                  <b-form-select
                    class="form-select"
                    v-model="formData.discountType"
                    :options="discountTypeOptions"
                    :class="{
                      'is-invalid':
                        $v.formData.discount.$error ||
                        $v.formData.discountType.$error,
                    }"
                  >
                  </b-form-select>
                </template>
              </b-input-group>
              <b-form-invalid-feedback id="marginFactor-feedback">
                {{
                  $v.formData.discountType.isValidDiscount == false
                    ? $t(`${moduleSlug}.discount-invalid-error-msg`)
                    : $t(`${moduleSlug}.discount-required-error-msg`)
                }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-12 col-md-6" v-if="showMarginFactor">
            <div role="group" class="form-group">
              <label for="marginFactor" class="form-label required">{{
                $t(`${moduleSlug}.margin_factor-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="marginFactor-tootltip"></i>
              <b-tooltip target="marginFactor-tootltip"
                ><p v-html="$t(`${moduleSlug}.margin_factor-tooltip`)"
              /></b-tooltip>
              <b-form-input
                id="marginFactor"
                v-model.number="formData.marginFactor"
                @keypress="onlyForMarginFactor"
                :class="{ 'is-invalid': $v.formData.marginFactor.$error }"
                :placeholder="$t(`${moduleSlug}.margin_factor-placeholder`)"
              ></b-form-input>
              <b-form-invalid-feedback id="marginFactor-feedback">
                {{ $t(`${moduleSlug}.margin_factor-required-error-msg`) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
        <div class="row">
          <div class="col-sm-12 col-md-6">
            <div id="startDate" role="group" class="form-group">
              <label for="startDate" class="form-label required">{{
                $t(`${moduleSlug}.start_date-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="startDate-tootltip"></i>
              <b-tooltip target="startDate-tootltip"
                ><p v-html="$t(`${moduleSlug}.start_date-tooltip`)"
              /></b-tooltip>

              <div
                class="flex w-full"
                :class="{ 'is-invalid': $v.formData.priceStartDate.$error }"
              >
                <v-date-picker
                  :locale="selected_language"
                  ref="priceStartDate"
                  class="flex-grow"
                  :class="{ 'is-invalid': $v.formData.priceStartDate.$error }"
                  :masks="masks"
                  v-model="formData.priceStartDate"
                  is-required
                  :popover="{ visibility: 'click' }"
                  :model-config="modelConfig"
                  :disabled-dates="[
                    {
                      start: null,
                      end: yesterday,
                    },
                  ]"
                  :available-dates="{
                    start: new Date(formData.priceStartDate),
                    end: new Date(formData.priceStartDate),
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <div class="d-flex custom-datepicker">
                      <input
                        class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        :value="inputValue"
                        @click="showStartDatePicker"
                        :placeholder="dateFormat"
                        readonly
                      />
                      <div
                        class="picker-icon"
                        :class="{
                          'is-invalid': $v.formData.priceStartDate.$error,
                        }"
                        slot="afterDateInput"
                        role="button"
                        @click="showStartDatePicker"
                      >
                        <i class="icon-date"></i>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>

              <b-form-invalid-feedback id="startDate-feedback">
                {{
                  formData.priceStartDate &&
                  $v.formData.priceStartDate.$error &&
                  $v.formData.priceStartDate.minValue == false
                    ? sytemMessages.minimumDateError
                    : sytemMessages.startDateRequired
                }}
              </b-form-invalid-feedback>
            </div>
          </div>
          <div class="col-sm-12 col-md-6">
            <div id="endDate" role="group" class="form-group">
              <label for="endDate" class="form-label">{{
                $t(`${moduleSlug}.end_date-label`)
              }}</label>
              <i class="icon-tooltip ml-1" id="endDate-tootltip"></i>
              <b-tooltip target="endDate-tootltip"
                ><p v-html="$t(`${moduleSlug}.end_date-tooltip`)"
              /></b-tooltip>

              <div
                class="flex w-full"
                :class="{ 'is-invalid': $v.formData.priceEndDate.$error }"
              >
                <v-date-picker
                  :locale="selected_language"
                  ref="priceEndDate"
                  class="flex-grow"
                  :masks="masks"
                  v-model="formData.priceEndDate"
                  :popover="{ visibility: 'click' }"
                  :model-config="modelConfig"
                  :disabled-dates="[
                    {
                      start: null,
                      end: yesterday,
                    },
                  ]"
                  :available-dates="{
                    start:
                      formData.priceEndDate != ''
                        ? new Date(formData.priceEndDate)
                        : new Date(),
                    end:
                      formData.priceEndDate != ''
                        ? new Date(formData.priceEndDate)
                        : null,
                  }"
                >
                  <template v-slot="{ inputValue }">
                    <div class="d-flex custom-datepicker">
                      <input
                        class="form-control bg-white rounded-top-right-0 rounded-bottom-right-0"
                        :class="{
                          'is-invalid': $v.formData.priceEndDate.$error,
                        }"
                        :value="inputValue"
                        @click="showEndDatePicker"
                        :placeholder="dateFormat"
                        readonly
                      />
                      <div
                        class="picker-icon"
                        :class="{
                          'is-invalid': $v.formData.priceEndDate.$error,
                        }"
                        slot="afterDateInput"
                        role="button"
                        @click="showEndDatePicker"
                      >
                        <i class="icon-date"></i>
                      </div>
                    </div>
                  </template>
                </v-date-picker>
              </div>

              <b-form-invalid-feedback id="priceEndDate-feedback">
                {{ $t(`${moduleSlug}.end_date-invalid-error-msg`) }}
              </b-form-invalid-feedback>
            </div>
          </div>
        </div>
      </b-form>
      <preview-form
        :formName="formData.name"
        showProductGroups
        showPriceEnding
        :priceEnding="
          enablePriceEndings ? priceEndingText(priceEndings) : 'N/A'
        "
        :showMarginFactor="showMarginFactor"
        :marginFactor="formData.marginFactor"
        :showDiscount="showDiscount"
        :discount="discountText"
        showStartDate
        :startDate="formData.priceStartDate | formatedDate"
        showEndDate
        :endDate="formData.priceEndDate | formatedDate"
        :moduleSlug="moduleSlug"
        :nameLabel="$t(`${moduleSlug}.name-label`)"
        :siteLabel="$t(`${moduleSlug}.selected_site-label`)"
        :chooseProductLabel="$t(`${moduleSlug}.choose_product-label`)"
        :priceEndingLabel="$t(`${moduleSlug}.price_ending-label`)"
        :discountLabel="$t(`${moduleSlug}.discount-label`)"
        :marginFactorLabel="$t(`${moduleSlug}.margin_factor-label`)"
        :startDateLabel="$t(`${moduleSlug}.start_date-label`)"
        :endDateLabel="$t(`${moduleSlug}.end_date-label`)"
        v-if="showFormPreview"
      />

      <div class="d-flex flex-row-reverse">
        <b-button
          id="validate-btn"
          @click="handleValidate"
          v-if="showForm"
          variant="brand-primary"
          >{{ $t(`${moduleSlug}.validate-btn-label`) }}
        </b-button>
        <b-button
          id="submit-btn"
          @click="submitForm"
          v-if="showFormPreview && !productPreviewFailed"
          variant="brand-primary"
          >{{ $t(`${moduleSlug}.submit-btn-label`) }}</b-button
        >
        <b-button
          id="edit-btn"
          @click="handleEdit"
          v-if="showFormPreview"
          variant="brand-secondary"
          class="mr-3"
          >{{ $t(`${moduleSlug}.edit-btn-label`) }}</b-button
        >
      </div>
    </b-card>
    <b-card v-if="productPreviewFailed || showFilteredProducts" class="mt-4">
      <product-preview-failed v-if="productPreviewFailed" />
      <product-preview
        v-if="showFilteredProducts"
        :formName="formName"
        :fileTitle="formData.name"
        :moduleSlug="formName"
      />
    </b-card>
    <b-modal
      dialog-class="success-modal"
      centered
      hide-header
      hide-footer
      ref="success-modal"
    >
      <div
        class="d-flex justify-content-center flex-column align-items-center text-center"
      >
        <i class="font-50 icon-completed mb-2"></i>
        <div class="message-detail">
          <h5 class="msg-title">
            {{ $t(`${moduleSlug}.success_message-title`) }}
          </h5>
          <i18n
            :path="`${moduleSlug}.success_message-desc`"
            tag="p"
            class="msg-desc m-0"
          >
            <b-link to="/pricing-history">{{
              $t(`${moduleSlug}.success_message-desc_term`)
            }}</b-link>
          </i18n>
        </div>
      </div>
    </b-modal>
  </div>
  <div v-else>
    <b-card class="mt-4">
      <form-success>
        <h5 class="msg-title">
          {{ $t(`${moduleSlug}.success_message-title`) }}
        </h5>
        <i18n
          :path="`${moduleSlug}.success_message-desc`"
          tag="p"
          class="msg-desc m-0"
        >
          <b-link to="/pricing-history">{{
            $t(`${moduleSlug}.success_message-desc_term`)
          }}</b-link>
        </i18n>
      </form-success>
    </b-card>
  </div>
</template>

<script>
import { eventBus } from "@/main";
import config from "../../../../config";
import ProductFilters from "../../../components/ProductFilters.vue";
import { mapGetters, mapMutations, mapActions } from "vuex";
import { forEach, map, max, isObject, trim, clone, isEmpty } from "lodash";
import { required, requiredIf, maxValue } from "vuelidate/lib/validators";
import moment from "moment";
import PreviewForm from "../../../components/PreviewForm.vue";
import ProductPreviewFailed from "../../../components/ProductPreviewFailed.vue";
import ProductPreview from "../../../components/ProductPreview.vue";
import FormSuccess from "../../../components/FormSuccess.vue";

const isValidProductGroup = (productGroup) => {
  let isValid = false;
  if (
    productGroup.vendor.length != 0 ||
    productGroup.category.length != 0 ||
    productGroup.productTags.length != 0 ||
    productGroup.productVendorModel.length != 0 ||
    productGroup.productSKU.length != 0
  ) {
    isValid = true;
  }

  return isValid;
};

const isInvalidProductGroup = (value) => {
  let nonValidatedProductGroups = [];
  let validateStatus = [];
  forEach(value, (productGroup, index) => {
    let isValid = isValidProductGroup(productGroup);
    validateStatus.push(isValid);
    if (!isValid) nonValidatedProductGroups.push(index + 1);
  });

  return validateStatus.includes(false);
};

const {
  discountType,
  formConfig,
  filterErrorObject,
  dateFormat,
  pricingManagerConfig,
} = config;

export default {
  name: "PricingForm",
  components: {
    ProductFilters,
    PreviewForm,
    ProductPreviewFailed,
    ProductPreview,
    FormSuccess,
  },
  props: {
    moduleSlug: {
      type: String,
      default: "everyday-pricing",
    },
    formName: {
      type: String,
      default: "everyday-pricing",
    },
    requiredFilters: Array,
    priority: Number,
    showMarginFactor: {
      type: Boolean,
      default: false,
    },
    showDiscount: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isPushPricing: pricingManagerConfig.isPushPricing,
      enableProductGroupBoxStyling: config.enableProductGroupBoxStyling,
      enableAddProductGroup: config.enableAddProductGroup,
      showForm: true,
      showFormPreview: false,
      showFilteredProducts: false,
      productPreviewFailed: false,
      isFormSubmitted: false,
      enablePriceEndings: formConfig.priceEndingDisableValue ? false : true,
      formData: {
        id: 0,
        name: "",
        siteId: "",
        priceStartDate: new Date(),
        priceEndDate: "",
        pricingEndings1: 2,
        pricingEndings2: "",
        marginFactor: "",
        discount: 0,
        discountType: discountType.percentageOff,
      },
      priceEndingOptions: [
        {
          value: formConfig.priceEndingNullValue,
          text: formConfig.priceEndingNull,
        },
        { value: 9, text: "9." },
        { value: 99, text: "99." },
      ],
      discountTypeOptions: [
        { value: discountType.percentageOff, text: "%" },
        { value: discountType.priceOff, text: "$" },
      ],
      dateFormat: dateFormat,
      discountType: discountType,
      masks: {
        input: dateFormat,
      },
      modelConfig: {
        type: "string",
        mask: "iso",
      },
    };
  },
  mounted() {
    this.resetForm();
    let preFilledData = this.preFilledData;
    if (
      preFilledData &&
      isObject(preFilledData) &&
      Object.keys(preFilledData).length > 0
    ) {
      this.formData.id = this.mode == "edit" ? preFilledData.Id : 0;
      this.formData.name =
        this.mode == "edit"
          ? preFilledData.Name
          : "Copy of " + preFilledData.Name;
      this.formData.siteId = preFilledData.SiteId;
      this.formData.priceStartDate = new Date(preFilledData.PriceStartDate);

      if (
        preFilledData.PriceEndDate == "2099-12-31T00:00:00" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00Z" ||
        preFilledData.PriceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.priceEndDate = "";
      } else {
        this.formData.priceEndDate = new Date(preFilledData.PriceEndDate);
      }

      this.formData.marginFactor = parseFloat(preFilledData.MarginFactor);
      this.formData.pricingEndings1 = String(preFilledData.PriceEndings).split(
        "."
      )[0];
      this.formData.pricingEndings2 = String(preFilledData.PriceEndings).split(
        "."
      )[1]
        ? String(preFilledData.PriceEndings).split(".")[1]
        : "00";
      this.enablePriceEndings =
        this.formData.pricingEndings1 == formConfig.priceEndingDisableValue
          ? false
          : true;
      this.formData.discount = parseFloat(preFilledData.Discount);
      this.formData.discountType = parseInt(preFilledData.DiscountType);

      let productcondition = preFilledData.productcondition;

      let _productGroups = [];

      let siteId = this.formData.siteId;

      let proccessData = productcondition.reduce(function (results, item) {
        let loopIndex = "key_" + item.ProductGroupId;

        results[loopIndex] = results[loopIndex] || [];

        results[loopIndex].vendor = results[loopIndex].vendor || [];
        results[loopIndex].category = results[loopIndex].category || [];
        results[loopIndex].productTags = results[loopIndex].productTags || [];
        results[loopIndex].productVendorModel =
          results[loopIndex].productVendorModel || [];
        results[loopIndex].productSKU = results[loopIndex].productSKU || [];

        if (item.ConditionType == "Vendor") {
          let vendorObj = {
            condition: trim(item.Condition),
            siteId: siteId,
            vendorId: trim(item.ConditionId),
            vendorName: trim(item.ConditionValue),
          };

          results[loopIndex].vendor.push(vendorObj);
        } else if (item.ConditionType == "Category") {
          let cateObj = {
            roomGroupingId: trim(item.ConditionId),
            roomGrouping: trim(item.ConditionValue),
            condition: trim(item.Condition),
            siteId: siteId,
            storeBrandId: item.storeBrandId ? trim(item.storeBrandId) : null,
          };

          results[loopIndex].category.push(cateObj);
        } else if (item.ConditionType == "ProductTag") {
          let tagObj = {
            id: trim(item.ConditionId),
            tag: trim(item.ConditionValue),
            condition: trim(item.Condition),
            siteId: this.formData.siteId,
          };

          results[loopIndex].productTags.push(tagObj);
        } else if (item.ConditionType == "VendorModelNumber") {
          let vmnObj = {
            productVendorModelId: trim(item.ConditionId),
            productVendorModel: trim(item.ConditionValue),
            condition: trim(item.Condition),
          };

          results[loopIndex].productVendorModel.push(vmnObj);
        } else if (item.ConditionType == "ProductSKU") {
          let skuObj = {
            productSKUId: trim(item.ConditionId),
            productSKU: trim(item.ConditionValue),
            condition: trim(item.Condition),
          };

          results[loopIndex].productSKU.push(skuObj);
        }
        return results;
      }, {});

      let _productGroupObject = {
        groupId: "1",
        vendor: [],
        category: [],
        productTags: [],
        productVendorModel: [],
        productSKU: [],
      };

      for (const key in proccessData) {
        let groupId = key.replace("key_", "");

        let obj = clone(_productGroupObject);
        obj.groupId = groupId;
        obj.vendor = proccessData[key]["vendor"];
        obj.category = proccessData[key]["category"];
        obj.productTags = proccessData[key]["productTags"];
        obj.productVendorModel = proccessData[key]["productVendorModel"];
        obj.productSKU = proccessData[key]["productSKU"];

        _productGroups.push(obj);

        setTimeout(() => {
          eventBus.$emit("setFilter", {
            id: groupId,
            filterData: [
              {
                filterName: "vendorFilter",
                filters: obj.vendor,
              },
              {
                filterName: "categoryFilter",
                filters: obj.category,
              },
              {
                filterName: "productTagFilter",
                filters: obj.productTags,
              },
              {
                filterName: "vmnFilter",
                filters: obj.productVendorModel,
              },
              {
                filterName: "skuFilter",
                filters: obj.productSKU,
              },
            ],
          });

          // eventBus.$emit("setVendorFilter", {
          //   id: groupId,
          //   vendor: obj.vendor,
          // });
          // eventBus.$emit("setCategoryFilter", {
          //   id: groupId,
          //   category: obj.category,
          // });
          // eventBus.$emit("setTagFilter", { id: groupId, tag: obj.productTags });
          // eventBus.$emit("setVmnFilter", {
          //   id: groupId,
          //   vmn: obj.productVendorModel,
          // });
          // eventBus.$emit("setSkuFilter", { id: groupId, sku: obj.productSKU });
        }, 900);
      }
      setTimeout(() => {
        this.setProductGroups({ type: "update", data: _productGroups });
      }, 700);
    }

    if (this.showMarginFactor == false) {
      this.formData.marginFactor = 0;
    }
  },
  computed: {
    ...mapGetters([
      "productGroups",
      "filteredProductError",
      "selectedSite",
      "selected_language",
    ]),
    ...mapGetters("auth", ["siteAccess"]),
    ...mapGetters("pricingManager", [
      "pricingManagerId",
      "mode",
      "preFilledData",
    ]),
    sitesList() {
      // let sites = [{ text: "Select site", value: "", disabled: true }];
      let sites = [];
      forEach(this.siteAccess, (site) => {
        let obj = { text: site.Name, value: site.SiteId };
        sites.push(obj);
      });
      return sites;
    },
    disabledEndDate() {
      let vm = this;
      return {
        customPredictor: function (date) {
          let start_date = vm.formData.priceStartDate;
          var diff = start_date.getTime() - date.getTime();
          var daydiff = diff / (1000 * 60 * 60 * 24);

          if (daydiff >= 1) return true;
          else return false;
        },
      };
    },
    availableProductGroups() {
      let group = [];
      forEach(this.productGroups, (productGroup, index) => {
        group.push(index + 1);
      });
      return group.join();
    },
    priceEndings() {
      return this.enablePriceEndings == false
        ? parseFloat(formConfig.priceEndingDisableValue)
        : parseFloat(
            this.formData.pricingEndings1 + "." + this.formData.pricingEndings2
          );
    },
    discountText() {
      return `${this.formData.discount} ${
        this.formData.discountType == discountType.percentageOff ? "%" : "$"
      }`;
    },
    controllerName() {
      let controllerName = "";
      switch (this.formName) {
        case "flyer-pricing":
          controllerName = "FlyerPricing.vue";
          break;
        case "promotional-pricing":
          controllerName = "PromotionalPricing.vue";
          break;
        default:
          controllerName = "EverydayPricing.vue";
          break;
      }
      return controllerName;
    },
    moduleName() {
      let moduleName = "";
      switch (this.formName) {
        case "flyer-pricing":
          moduleName = "Flyer Pricing";
          break;
        case "promotional-pricing":
          moduleName = "Promotional Pricing";
          break;
        default:
          moduleName = "Everyday Pricing";
          break;
      }
      return moduleName;
    },
  },
  methods: {
    ...mapActions([
      "addNewProductGroup",
      "setProductGroups",
      "resetFormState",
      "getPricingFilteredProducts",
    ]),
    ...mapActions("pricingManager", ["submitPricingForm"]),
    ...mapMutations(["setItem"]),
    ...mapMutations("pricingManager", {
      pricingSetItem: "setItem",
    }),
    createNewProductGroup() {
      let groupIds = map(this.productGroups, (_data) => Number(_data.groupId));
      let lastGroupId = max(groupIds);
      this.addNewProductGroup(lastGroupId + 1);
    },
    removeProductGroup(index) {
      let _productGroup = this.productGroups;
      _productGroup.splice(index, 1);
      this.setProductGroups({ type: "update", data: _productGroup });
    },
    showStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
        this.$refs.priceStartDate.togglePopover();
      });
    },
    showEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
        this.$refs.priceEndDate.togglePopover();
      });
    },
    hideStartDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceStartDate.hidePopover();
      });
    },
    hideEndDatePicker() {
      this.$nextTick(() => {
        this.$refs.priceEndDate.hidePopover();
      });
    },
    showSuccessModal() {
      this.$refs["success-modal"].show();
    },
    hideSuccessModal() {
      this.$refs["success-modal"].hide();
    },
    async handleValidate() {
      this.$v.formData.$touch();
      this.$v.productGroups.$touch();
      this.productPreviewFailed = false;

      if (
        this.$v.formData.$invalid ||
        this.$v.productGroups.isInvalidProductGroup
      ) {
        return;
      } else {
        let _priceEndDate = "2099-12-31T00:00:00Z";

        this.formData.priceEndDate =
          this.formData.priceEndDate == "" || this.formData.priceEndDate == null
            ? _priceEndDate
            : new Date(this.formData.priceEndDate).toISOString();

        let formData = Object.assign({}, this.formData);

        formData.userId = this.loggedUserId;
        formData.priceTypeId = this.priority;
        formData.productGroups = this.productGroups;
        formData.priceStartDate = new Date(
          formData.priceStartDate
        ).toISOString();
        formData.priceEndings = this.priceEndings;

        delete formData.pricingEndings1;
        delete formData.pricingEndings2;

        formData.exceptionParams = {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        };

        await this.getPricingFilteredProducts(formData);

        if (this.filteredProductError == false) {
          this.showForm = false;
          this.showFilteredProducts = this.showFormPreview = true;
        } else {
          this.productPreviewFailed = true;
        }
      }
    },
    onlyForMarginFactor($event) {
      let isNumber = this.isNumber($event);
      if (isNumber) {
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;
        let marginFactor = String(this.formData.marginFactor);

        // only allow number and one dot
        if (
          (keyCode < 48 || keyCode > 57) &&
          (keyCode !== 46 || marginFactor.indexOf(".") != -1)
        ) {
          // 46 is dot
          $event.preventDefault();
        }
      }
    },
    onlyForDiscount($event) {
      let isNumber = this.isNumber($event);
      if (isNumber) {
        let discount = String(this.formData.discount);
        let keyCode = $event.keyCode ? $event.keyCode : $event.which;

        // only allow number and one dot
        if (
          (keyCode < 48 || keyCode > 57) &&
          (keyCode !== 46 || discount.indexOf(".") != -1)
        ) {
          // 46 is dot
          $event.preventDefault();
        }
      }
    },
    handleEdit() {
      this.setItem({ resource: "filteredProductError", payload: false });
      this.showForm = true;
      this.showFormPreview =
        this.showFilteredProducts =
        this.productPreviewFailed =
          false;
      if (
        this.formData.priceEndDate == "2099-12-31T00:00:00" ||
        this.formData.priceEndDate == "2099-12-31T00:00:00Z" ||
        this.formData.priceEndDate == "2099-12-31T00:00:00.000Z"
      ) {
        this.formData.priceEndDate = "";
      }
      setTimeout(() => {
        eventBus.$emit("updateFiltersInEdit");
      }, 700);
    },
    priceEndingText(value) {
      let valueArry = String(value).split(".");
      if (valueArry[0] == 2) {
        return `0.${valueArry[1]}`;
      }
      return value;
    },
    resetForm() {
      this.$v.$reset();
      this.resetFormState();
      this.showForm = true;
      this.showFormPreview = false;
      this.showFilteredProducts = false;
      this.productPreviewFailed = false;

      this.formData.id = 0;
      this.formData.name = "";
      this.formData.priceStartDate = new Date();
      this.formData.priceEndDate = "";
      this.formData.pricingEndings1 = 2;
      this.formData.pricingEndings2 = "";
      this.formData.marginFactor = "";
      this.formData.discount = 0;
      this.formData.discountType = discountType.percentageOff;
      this.enablePriceEndings = false;
    },
    submitForm() {
      this.setItem({ resource: "isLoading", payload: true });
      this.submitPricingForm({
        id: this.pricingManagerId,
        exceptionParams: {
          userId: this.loggedUserId,
          controllerName: this.controllerName,
          moduleName: this.moduleName,
        },
      })
        .then((response) => {
          this.setItem({ resource: "isLoading", payload: false });

          if (response.status == 1) {
            // this.isFormSubmitted = true;
            this.showSuccessModal();
            this.formData.siteId = "";
          }
        })
        .catch(() => {
          this.setItem({ resource: "isLoading", payload: false });
        });
    },
  },
  watch: {
    "formData.siteId": function (newVal, oldVal) {
      let selectedSite = this.siteAccess.find((site) => site.SiteId == newVal);

      if (oldVal != "") this.resetForm();
      if (!selectedSite) return;

      this.setItem({ resource: "selectedSite", payload: selectedSite });
      let _filterErrorObject = Object.assign({}, filterErrorObject);
      this.setItem({ resource: "filterError", payload: _filterErrorObject });
      eventBus.$emit("resetFilters");
    },
    selected_language() {
      this.$v.$reset();
    },
  },
  beforeDestroy() {
    this.resetForm();
  },
  validations: {
    formData: {
      name: { required },
      siteId: { required },
      pricingEndings1: {
        required: requiredIf(function () {
          return this.enablePriceEndings;
        }),
      },
      pricingEndings2: {
        required: requiredIf(function () {
          return this.enablePriceEndings;
        }),
        maxValue: maxValue(99),
      },
      marginFactor: {
        required: requiredIf(function () {
          return this.showMarginFactor;
        }),
        isValidMarginFactor(value) {
          if (this.showMarginFactor == true && value < 1) {
            return false;
          }
          return true;
        },
      },
      discount: {
        required: requiredIf(function () {
          return this.showDiscount;
        }),
        isValidDiscount(value) {
          if (
            this.showDiscount == true &&
            this.formData.discountType == 0 &&
            (value == 0 || value > 100)
          ) {
            return false;
          }
          return true;
        },
      },
      discountType: {
        required: requiredIf(function () {
          return this.showDiscount;
        }),
      },
      priceStartDate: {
        required,
        minValue(value) {
          let sdate = moment(value);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = "";

          if (!isEmpty(this.formData.priceEndDate)) {
            edate = moment(this.formData.priceEndDate);
            edate.set("hour", 0);
            edate.set("minute", 0);
            edate.set("second", 0);
            edate.set("millisecond", 0);

            if (
              moment(edate)._i == "2099-12-31T00:00:00" ||
              moment(edate)._i == "2099-12-31T00:00:00Z" ||
              moment(edate)._i == "2099-12-31T00:00:00.000Z"
            ) {
              edate = "";
            }
          }

          let status = this.checkStartDateValidation(sdate, edate, this.mode);

          return status;
        },
      },
      priceEndDate: {
        minValue(value) {
          let sdate = moment(this.formData.priceStartDate);
          sdate.set("hour", 0);
          sdate.set("minute", 0);
          sdate.set("second", 0);
          sdate.set("millisecond", 0);

          let edate = moment(value);
          edate.set("hour", 0);
          edate.set("minute", 0);
          edate.set("second", 0);
          edate.set("millisecond", 0);

          if (
            moment(edate)._i == "2099-12-31T00:00:00" ||
            moment(edate)._i == "2099-12-31T00:00:00Z" ||
            moment(edate)._i == "2099-12-31T00:00:00.000Z"
          ) {
            edate = "";
          }

          let status = this.checkEndDateValidation(sdate, edate);

          return status;
        },
      },
    },
    productGroups: {
      isInvalidProductGroup,
    },
  },
};
</script>
